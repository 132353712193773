// Header text feature
.call-out {
  @extend %padding-regular;
  display: inline-block;
  width: 100%;
  background-color:$header-desc-background-color;
  background-size: cover;
  font-size: 1.2em;
  text-align: center;
  color: $header-desc-text-color;
    
  img{
    //box-shadow: 0px 0px 5px @shadow_color;
    max-width: 500px;
    margin: 10px;
    box-shadow: 5px $text-color;
    border-radius: 50%;
    width: 30%;
    height: 30%;
    float: right;
    text-shadow: 0px 0px 50px #03138d;
  }
    
  p{
    text-shadow: 0px 0px 20px #222;
    padding-top: 7.5vw;
  }
}
.call-out p:last-child {
  margin-bottom: 0;
}
// Post listing
.posts {
  .post-teaser {
    @extend %padding-regular;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
    background-size: $feature-image-size;
    border-bottom: 1px solid $border-color;
    p a:hover {
      @extend %link-hover;
    }
  }
  .excerpt {
    margin-top: 1em;
  }
}
// Pagination
.pagination .button {
  margin: 0 1.5em;
  i {
    vertical-align: middle;
  }
}
.pagination {
  padding: $padding-small $padding-large 0 $padding-large;
  text-align: center;
}
